import { useEffect, useRef, useState } from 'react';
import apiCall,{URLS} from '../../webservice/ApiCallService';
import { useSelector } from 'react-redux';
import "./custom-admin.css";
import toast from 'react-hot-toast';

export default function Payments()
{
    const loginUserToken = useSelector((store) => store.user.value.token); 
    
    const [payments,setPayments] = useState([]);   

    useEffect(()=>{
        loadPayments();
    },[])

    const loadPayments = async()=>
    {
        const result = await apiCall.getCallWithToken(URLS.LIST_ALL_SUBSCRIPTION,loginUserToken);
        if(result.status){
          setPayments(result.data);
          console.log(result.data);
        }
    }

    return <div className="container-fluid pt-3">
            <h3 id="HeadText">Subscription Payments</h3>
            <table className="table mt-3">
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Client</th>
            <th>Plan</th>
            <th>Payment Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody style={{fontWeight:'bold',color:'black'}}>
            {payments.map((ob,index)=><tr key={`pp${index}`}>
              <td>{index+1}</td>
              <td>
                <b>{ob.client.name}</b> <br/>
                {ob.client.mobile}
              </td>
              <td>
                <b>{ob.plan_name}</b> <br/>
                {ob.duration_month} Days
              </td>
              <td>{ob.payment_date.split("T")[0]}</td>
              <td>
                {ob.amount}
                <br/>
                <b className='btn alert-danger'>Ref : {ob.reference}</b>  
              </td>
            </tr>)}
        </tbody>
      </table>
        </div>
}