import { useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiCall , {URLS} from '../../webservice/ApiCallService'
import {delUserData} from '../../reduxconfig/UserSlice';

export default function ChangePassword() 
{
  const loginUser = useSelector((store) => store.user.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const oldpassRef = useRef();
  const newpassRef = useRef();


  const change = (event) => 
  {
    event.preventDefault();

    const data = {
      oldpass: oldpassRef.current.value,
      newpass: newpassRef.current.value,
    };

    toast.promise(apiCall.postCallWithToken(URLS.CHANGE_PASSWORD, loginUser.token ,data),{
        loading : 'Password Changing ...',
        success : (result)=>{
            if (result.status) 
            {         
              toast.success(result.msg);
              event.target.reset();
            }else
            {
              toast.error(result.msg) 
            }
        }
      })    
  };

  return (
    <>
    <div className="container Broker">
            <div className="row mt-3">
                <div className='col-xl-10 col-lg-10'>
                <h3 id="HeadText">Change Password</h3>
            </div>
            

        <form method="" onSubmit={change}>
          <div className="row justify-content-center p-2 mt-5">
            <div className="col-lg-10 col-md-5 ">
                <input                
                type="password"
                required
                placeholder="Old Password"
                className="form-control"
                ref={oldpassRef}
              />
            </div>
          </div>
          <div className="row justify-content-center p-2">
            <div className="col-lg-10 col-md-5">
              <input
                name="Message"
                type="password"
                required
                placeholder="New Password"
                className="form-control"
                ref={newpassRef}
              />
            </div>
          </div>
          <div className="text-center mt-2 ">
            <button
              type="submit"
              name="Submit"
              className="btn btn-primary btn-md formbtn"
            >
              Change
            </button>
            &nbsp;&nbsp; 
          </div>
        </form>
      </div>
      </div>
    </>
  );
}
