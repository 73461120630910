import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';
import { FaCalendar, FaEnvelopeSquare, FaHome, FaMoneyBill, FaSignOutAlt, FaUser, FaWrench } from "react-icons/fa"; 
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export default function AdminSideNav() 
{
  const loginUserRole = useSelector((store) => store.user.value.role);
  useEffect(()=>{
    window.sideBarTog()
  },[])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = ()=>
  {    
      toast.success('Thanks , See You Soon !')
      dispatch(delUserData())
      navigate("/user/login")
  }

  return (
    <>
      <ul
        className=" navbar-nav  sidebar sidebar-dark accordion pt-2"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <div
          className="mt-3 sidebar-brand d-flex align-items-center justify-content-center" style={{mixBlendMode:"luminosity"}}
        >
          <img src="/images/logo.png" className='rounded'  alt="logoimg"/>
        </div>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-1" />

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">Master Data</div>

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <Link className="nav-link" to={`/${loginUserRole}/dashboard`}>
              <FaHome/>
              <span className='ml-2'>Dashboard</span>
          </Link>
        </li>
        
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
            style={{cursor:'pointer'}}
          >
            <FaUser/>
            <span className='ml-2'>Users</span>
          </a>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
             
              <Link className="collapse-item" to="/admin/managers">
                Managers
              </Link>
              <Link className="collapse-item" to="/admin/client">
                Client
              </Link>
              <Link className="collapse-item" to="/admin/blocked_users">
                Blocked Users
              </Link>
              <Link className="collapse-item" to="/admin/payments">
                Payments
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            
            data-toggle="collapse"
            data-target="#collapseStr"
            aria-expanded="true"
            aria-controls="collapseStr"
            style={{cursor:'pointer'}}
          >
            <FaWrench/>
            <span className='ml-2'>Strategies</span>
          </a>
          <div
            id="collapseStr"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
             
              <Link className="collapse-item" to="/admin/strategy">
                Add Strategy
              </Link>
              <Link className="collapse-item" to="/admin/viewstrategy">
                View Strategies
              </Link>            
             
            </div>
          </div>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        {/* <!-- Heading --> */}
        <div className="sidebar-heading">Addons</div>

        {/* <!-- Nav Item - Charts --> */}
        <li className="nav-item">
          {/* <a className="nav-link" href="">
              <FaChartArea/>
              <span className='ml-2'>Charts</span>
          </a> */}
        </li>

        {/* <!-- Nav Item - Tables --> */}
        <li className="nav-item">
          {/* <a className="nav-link" href="">
            <FaTabletAlt/>
            <span className='ml-2'>Reports</span>
          </a> */}
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/admin/messages">
          <FaEnvelopeSquare/>
            <span className='ml-2'>Messages</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/admin/holidays">
          <FaCalendar/>
            <span className='ml-2'>Holidays</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/admin/plans">
          <FaMoneyBill/>
            <span className='ml-2'>Subscription Plans</span>
          </Link>
        </li>

        <li className="nav-item">
          <b className="nav-link" style={{cursor:'pointer'}} onClick={logout}>
            <FaSignOutAlt/>
            <span className='ml-2'>LogOut</span>
          </b>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul>
    </>
  );
}
