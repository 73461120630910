import { useEffect, useRef, useState } from 'react';
import apiCall,{URLS} from '../../webservice/ApiCallService';
import { useSelector } from 'react-redux';
import "./custom-admin.css";
import toast from 'react-hot-toast';

export default function Plans()
{
    const loginUserToken = useSelector((store) => store.user.value.token); 
    
    const [plans,setPlans] = useState([]);

    const titleRef = useRef();
    const monthRef = useRef();
    const amtRef = useRef();
    const offAmtRef = useRef();

    useEffect(()=>{
        loadPlans();
    },[])

    const loadPlans = async()=>
    {
        const result = await apiCall.getCall(URLS.LIST_PLANS);
        if(result.status){
            setPlans(result.data);
        }
    }

    const save = (e)=>{
        e.preventDefault();
        var ob = {
          title : titleRef.current.value ,
          month : monthRef.current.value*1,
          amount : amtRef.current.value*1,
          offerAmount : offAmtRef.current.value*1
        }
        setPlans([...plans,ob]);
        e.target.reset();
      }

    const send = async ()=>
    {
          const result = await apiCall.postCallWithToken(URLS.SAVE_PLANS,loginUserToken,{plans});
          toast.success(result.msg);
    }

    const deletePlan = (i)=>{
        setPlans(plans.filter((d,idx)=>idx!=i));
      }

    return <div className="container-fluid pt-3">
            <h3 id="HeadText">Subscription Plans</h3>
            <form onSubmit={save}>
           <div className='row mt-3'>
              <div className='col-xl-4 col-lg-4'>
                <input type='text' className='form-control' ref={titleRef} placeholder='Title' required/>
              </div>
              <div className='col-xl-2 col-lg-2'>
                <input type='number' className='form-control' ref={monthRef} placeholder='Days' required/>
              </div>
              <div className='col-xl-2 col-lg-2'>
                <input type='number' className='form-control' ref={amtRef} placeholder='Amount' required/>
              </div>
              <div className='col-xl-2 col-lg-2'>
                <input type='number' className='form-control' ref={offAmtRef} placeholder='Offer Amount' required/>
              </div>
              <div className='col-xl-2 col-lg-2 text-center'>
                  <button className='btn btn-success'>Add</button>
                  &nbsp;
                  <button className='btn btn-primary' onClick={send} type='button'>Save</button>
              </div>
            </div>
           </form>
            <hr/>

            <table className='table'>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                  <th>Days</th>
                  <th>Price</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {plans.map((d,i)=><tr key={i}>
                  <td>{i+1}</td>
                  <td>{d.title}</td>
                  <td>{d.month}</td>
                  <td><span style={{textDecoration:'line-through'}}>{d.amount}</span> {d.offerAmount}</td>
                  <td>
                    <button className='btn-sm btn-danger' onClick={()=>deletePlan(i)}>Delete</button>
                  </td>
                </tr>)}
              </tbody>
            </table>
        </div>
}