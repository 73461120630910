import React from "react";

const plansHOF = (WrappedComponent, eventHandlers) => {
  return (props) => {
    const handleClick = (eventName, ...args) => {
      if (eventHandlers[eventName]) {
        eventHandlers[eventName](...args);
      } else {
        console.warn(`Event handler for ${eventName} is not defined.`);
      }
    };

    return <WrappedComponent {...props} onClickEvent={handleClick} />;
  };
};

export default plansHOF;
