import "./PricingPlans.css";
import { useEffect, useRef, useState } from 'react';
import apiCall,{URLS} from '../../webservice/ApiCallService';

const PricingPlans = ({ onClickEvent }) => 
{
  const features = [
      "Pre Build Strategies",
      "Auto Buy/Sell",
      "Proper Risk Management",
      "Free API Installation",
      "No Hidden Charges",
  ]

  const [plans,setPlans] = useState([]);
  useEffect(()=>{
    loadPlans();
  },[])

  const loadPlans = async()=>
  {
      const result = await apiCall.getCall(URLS.LIST_PLANS);
      if(result.status){
          setPlans(result.data);
      }
  }

 
  return (
    <div className="pricing-plans-container">
      <h1 className="pricing-title">Pricing Plans</h1>
      <div className="pricing-cards">
        {plans.sort((p1,p2)=>p1.offerAmount-p2.offerAmount).map((plan, index) => (
          <div
            className={`pricing-card ${
              plan.title.includes("Premium") ? "premium-card" : ""
            }`}
            key={index}
          >
            <h2 className="plan-title">{plan.title}</h2>
            <p className="plan-price">
              <span className="original-price">{plan.amount}</span>
              <span className="discounted-price">
                {plan.offerAmount} +GST
              </span>
            </p>
            <ul className="plan-features">
               <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color:'red',
                    fontWeight : 'bold'
                  }}
                >
                  <img
                    height={"20px"}
                    width={"20px"}
                    src="/images/checkiconsvg.svg"
                    alt="check"
                  />{" "}
                    <span style={{ marginLeft: "5%" }}>{plan.month} Days Access</span>
                </li>
              {features.map((feature, i) => (
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={i}
                >
                  <img
                    height={"20px"}
                    width={"20px"}
                    src="/images/checkiconsvg.svg"
                    alt="check"
                  />{" "}
                    <span style={{ marginLeft: "5%" }}>{feature}</span>
                </li>
              ))}
            </ul>
            <button className="see-features-btn bold text-center font-weight-bold" onClick={() => onClickEvent("onSelectPlan",plan)}>S E L E C T</button>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default PricingPlans;
