import React, { useEffect, useState } from 'react';
import './custom-client.css';
import { Settings } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';
import toast from 'react-hot-toast';

import apiCall , {URLS} from '../../webservice/ApiCallService';

export default function ClientNav() 
{
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [prices,setPrices] = useState({
    Nifty50 : 0 , BankNifty : 0 , Sensex : 0
  });
  const indexes = {
    "^NSEI" : 'Nifty50', 
    "^NSEBANK" : 'BankNifty',
    "^BSESN" : 'Sensex'
  }
  
  const fetchPrice = async ()=> 
  {
    
  }


  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const result = await apiCall.getCall(URLS.PRICES);
        if (result.status) {
          const data = result.data;
          const priceData = Object.fromEntries(
            data.map(ob => {
              const exchange = indexes[ob.ticker]; // Ensure 'indexes' is defined
              const price = ob.currentPrice;
              return [exchange, price];
            })
          );
          setPrices(priceData); // Update state with the processed data
        }
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    }, 2000);
  
    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []); // Add 'indexes' if it's a dependency
  
  const logout = ()=>
  {    
      toast.success('Thanks , See You Soon !')
      dispatch(delUserData())
      navigate("/user/login")
  }

  return <>
     <nav className="navbar">
      <div className="navbar-brand"></div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"2px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        NIFTY 50 : {prices.Nifty50.toFixed(2)}
      </div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"3px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        Bank NIFTY : {prices.BankNifty.toFixed(2)}
      </div>
      <div className='h-100 px-2 rounded' style={{background:"linear-gradient(to top, #4ca1af, #c4e0e5) 70%",letterSpacing:"3px",color:'black',fontWeight:700,textShadow:'1px 1px yellow'}}>
        Sensex : {prices.Sensex.toFixed(2)}
      </div>
      <div className="navbar-icons">
        <div className="icon-container profile">
          <Settings/>
          <div className="dropdown-menu">
            <ul>
              <li>My Profile</li>
              <li><Link to="/client/changepassword">Change Password</Link></li>
              <li onClick={logout}>Logout</li>
            </ul>
          </div>
        </div>

        {/* Profile with hover dropdown */}
        
      </div>
    </nav>
    <hr/>
  </>
}
