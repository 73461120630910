import { useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { FaClipboard, FaICursor } from "react-icons/fa";
import { FaClone } from "react-icons/fa6";
import apiCall , {URLS} from "../../webservice/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../reduxconfig/UserSlice";

export default function PaymentPop({ plan , handlePopClose, show }) 
{
  const loginUser = useSelector((store) => store.user.value);
  const dispatch = useDispatch();

  const [utrNum, setUtrNum] = useState("");
  let popObj = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    height: "fit-content",
    maxHeight: "580px",
    width: "max-content",
    maxWidth: "70%",
    minWidth: "400px",
    backgroundColor: "white",
    borderRadius: "10px",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "none",
    outline:"4px double #d3b557",
    boxShadow:"0px 0px 20px 1px  #eebd75"
  };
  const utrBox = useRef(null);
  const upiBox = useRef();
  const copyUpiId = () => {
    navigator.clipboard
      .writeText(upiBox.current.innerText.trim())
      .then(() => {
        toast.success("COPIED");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const send = async ()=>
  {
    const data = {
      plan_name : plan.title,
      duration_month : plan.month, 
      amount : plan.offerAmount + ((plan.offerAmount*18)/100),
      reference : utrBox.current.value
    }

    const result = await apiCall.postCallWithToken(URLS.SAVE_SUBSCRIPTION,loginUser.token,data);
    if(result.status){
      utrBox.current.value = "";
      toast.success(result.msg);
      dispatch(setUserData({...loginUser,plan:{ status:true,end:result.data.plan_end_date}}));
      handlePopClose()
    }else{
      toast.error(result.msg)
    }
  }

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
    >
      <div style={popObj} className="p-2">
        {/* <div className="modal-header">
          <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true" onClick={handlePopClose}>
              &times;
            </span>
          </button>
        </div> */}
        <div className="container-fluid border rounded">
          {/* <!-- Header Section --> */}
          <div className="text-center mb-1">
            <p className="fw-bold mb-1" style={{color:"black",fontSize:"0.9em",lineHeight:"1"}}>
              Scan & Pay the exact amount only
              <br />
              (upto 1 lakh) submit UTR
            </p>
          </div>

          {/* <!-- Transfer Amount --> */}
          <div className="d-flex justify-content-between align-items-center mb-1">
            <span className="fw-bold text-info">Transfer Amount</span>
            <span className="fw-bold text-success fs-4">
              ₹ {plan.offerAmount + ((plan.offerAmount*18)/100)}</span>
          </div>

          {/* <!-- QR Code --> */}
          <div className="text-center mb-1">
            <img
              src="/images/QRCODEFORPROFIT.jpg"
              alt="QR Code"
              className="img-fluid border border-warning rounded"
              style={{ maxWidth: "200px" }}
            />
          </div>

          {/* <!-- UPI ID --> */}
          <div className="d-flex align-items-center justify-content-center mb-1">
            <div>
              <span className="fw-bold text-dark">UPI ID :</span>
              <span style={{color:"black"}} className="pl-4 pr-4" ref={upiBox}>
                8696066004@ptsbi
              </span>
            </div>
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={copyUpiId}
            >
              <FaClone title="COPY UPI ID"/>
              {/* <!-- Bootstrap Clipboard Icon --> */}
            </button>
          </div>

          {/* <!-- Input Field & Submit Button --> */}
          <div className="d-flex gap-3">
            <input
              type="text"
              className="form-control"
              placeholder="Enter UTR Number"
              aria-label="UTR Number"
              ref={utrBox}
              onInput={() => setUtrNum(utrBox.current.value)}
            />
            <button
              className="btn btn-md"
              style={{
                background: `rgb(166, 22, 228,${utrNum.length ? 1 : 0.2})`,
                color: "white",
              }}
              disabled={!utrNum.length}
              onClick={send}
            >
              Submit
            </button>
          </div>

          {/* <!-- Note Section --> */}
          <div className="text-center mt-2">
            <p className="text-danger fw-light bg-danger text-light p-1 rounded">
              Note : Please Send Transaction ID After Successful Payment
            </p>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-sm"
          style={{background:"black",color:"white"}}
          onClick={handlePopClose}
        >
          Close
        </button>
      </div>
      <Toaster />
    </div>
  );
}
