import { useRef } from "react"
import apiCall, { URLS } from "../../webservice/ApiCallService";
import toast from "react-hot-toast";
export default function ForgetPop({show,handlePopClose})
{
  const mailRef = useRef()

  const send = async (e)=>{
    e.preventDefault();
    const result = await apiCall.postCall(URLS.RESET_PASSWORD,{email : mailRef.current.value});
    if(result.status)
      toast.success(result.msg)
    else  
      toast.error(result.msg)
    handlePopClose(false);
  }

  return <div
    className={`modal fade ${show ? "show d-block" : ""}`}
    tabIndex="-1"
    role="dialog"
    style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
  >
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title text-md">FORGOT PASSWORD</h5>
          <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true" onClick={handlePopClose}>&times;</span>
          </button>
        </div>
        <div className="modal-body p-4 d-flex flex-column justify-content-around g-2 align-items-center">
          <form onSubmit={send}>
              <input className="form-control" ref={mailRef} type="email" required placeholder="Enter Email"/>
              <span>
              <button className="bg-success p-2 rounded btn btn-sm text-white mt-2" style={{letterSpacing:"3px"}}>SEND</button></span>
          </form>
        </div>
      </div>
    </div>
  </div>
}