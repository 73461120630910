import { useEffect, useRef, useState } from "react";
import apiCall, { URLS } from "../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import "./custom-admin.css";
import toast from "react-hot-toast";

export default function Holidays() {
  const loginUserToken = useSelector((store) => store.user.value.token);

  const [apiDays, setApidays] = useState([]);

  const [days, setDays] = useState([]);

  const titleRef = useRef();
  const dateRef = useRef();

  useEffect(() => {
    loadHolidays();
    loadApiDays();
  }, []);

  const loadApiDays = async () => {
    try {
      const URL =
        "https://calendarific.com/api/v2/holidays?api_key=URuQbbcQBMqakVQinmvPWwLrevfRTgKc&country=IN&year=" +
        new Date().getFullYear();
      const result = await apiCall.getCall(URL);

      const holidays = result.response.holidays;
      const d = holidays.filter((h) => h.type.includes("National holiday"));
      const data = d.map((ob) => {
        return { title: ob.name, date: ob.date.iso };
      });
      setApidays(data);
    } catch (err) {}
  };

  const loadHolidays = async () => {
    const result = await apiCall.getCallWithToken(
      URLS.LIST_HOLIDAYS,
      loginUserToken
    );
    if (result.status) {
      setDays(result.data);
    }
  };

  const save = (e) => {
    e.preventDefault();
    var ob = {
      title: titleRef.current.value,
      date: dateRef.current.value,
    };
    setDays([...days, ob]);
    e.target.reset();
  };

  const deleteDay = (i) => {
    setDays(days.filter((d, idx) => idx != i));
  };

  const send = async () => {
    const result = await apiCall.postCallWithToken(
      URLS.SAVE_HOLIDAYS,
      loginUserToken,
      { holidays: days }
    );
    toast.success(result.msg);
  };
  return (
    <div className="container-fluid pt-3">
      <h3 id="HeadText">National Holidays</h3>
      <form onSubmit={save}>
        <div className="row mt-3">
          <div className="col-xl-4 col-lg-4">
            <input
              type="text"
              className="form-control"
              ref={titleRef}
              placeholder="Title"
              required
            />
          </div>
          <div className="col-xl-4 col-lg-4">
            <input
              type="date"
              className="form-control"
              ref={dateRef}
              placeholder="Date"
              required
            />
          </div>
          <div className="col-xl-4 col-lg-4 text-center">
            <button className="btn btn-success">Add Day</button>
            &nbsp; &nbsp;
            <button className="btn btn-primary" onClick={send} type="button">
              Save Days
            </button>
          </div>
        </div>
      </form>
      <hr />
      <b
        style={{
          background: "linear-gradient(rgb(166, 22, 228), rgb(255, 162, 0))",
          color: "transparent",
          backgroundClip: "text",
        }}
        className="text-danger"
      >
        Suggestions
      </b>{" "}
      <br />
      <br />
      <div className="row">
        {apiDays.map((d) => (
          <div
            onClick={() => setDays([...days, d])}
            className={
              days.some((dd) => dd.title == d.title)
                ? "col-xl-2 col-lg-2 shadow p-2 text-center rounded "
                : "col-xl-2 col-lg-2"
            }
            style={{
              paddingLeft: "auto",
              paddingRight: "auto",
              margin: "10px",
              alignContent: "center",
              cursor: "pointer",
              background:
                "linear-gradient(rgb(207, 32, 241,0.2),rgb(247, 160, 62,0.3),rgb(247, 160, 62,0.3),rgb(247, 160, 62,0.3),rgb(207, 32, 241,0.2))",
            }}
          >
            <b className="text-dark">{d.title}</b>
          </div>
        ))}
      </div>
      <hr />
      <table className="table">
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Title</th>
            <th>Date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {days.map((d, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{d.title}</td>
              <td>{d.date}</td>
              <td>
                <button
                  className="btn-sm btn-danger"
                  onClick={() => deleteDay(i)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
