import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {delUserData} from '../../reduxconfig/UserSlice';
import {addBrokers} from '../../reduxconfig/ClientDataSlice';
import {FaCreditCard, FaHome, FaSignOutAlt, FaSubscript, FaWrench } from "react-icons/fa"; 
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaUserTie } from 'react-icons/fa6';

import apiCall, { URLS } from "../../webservice/ApiCallService";

export default function ClientSideNav() 
{
  const loginUser = useSelector((store) => store.user.value);

  useEffect(()=>{
    window.sideBarTog();
    loadBrokers();
  },[])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = ()=>
  {    
      toast.success('Thanks , See You Soon !')
      dispatch(delUserData())
      navigate("/user/login")
  }

  const loadBrokers = () => 
  {
      apiCall
        .getCallWithToken(URLS.LIST_CLIENT_BROKERS, loginUser.token)
        .then((result) => {
          if (result.status) 
            dispatch(addBrokers(result.data));
          else dispatch(addBrokers([]));
        });
  }; 

  return (
    <>
      <ul
        className=" navbar-nav  sidebar sidebar-dark accordion pt-2"
        id="accordionSidebar"
      >
        {/* <!-- Sidebar - Brand --> */}
        <div
          className="mt-3 sidebar-brand d-flex align-items-center justify-content-center" style={{mixBlendMode:"luminosity"}}
        >
          <img src="/images/logo.png" className='rounded'  alt="logoimg"/>
        </div>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider my-1" />

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />       

        {/* <!-- Nav Item - Pages Collapse Menu --> */}
        <li className="nav-item">
          <Link className="nav-link" style={{cursor:'pointer'}} to={`/${loginUser.role}/dashboard`}>
              <FaHome/>
              <span className='ml-2'>Dashboard</span>
          </Link>
        </li>
        
        <li className="nav-item">
          <Link className="nav-link" style={{cursor:'pointer'}} to="/client/brokers">
          <FaUserTie/>
            <span className='ml-2' >Brokers</span>
          </Link>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            
            data-toggle="collapse"
            data-target="#collapseStr"
            aria-expanded="true"
            aria-controls="collapseStr"
            style={{cursor:'pointer'}}
          >
            <FaWrench/>
            <span className='ml-2'>Strategies</span>
          </a>
          <div
            id="collapseStr"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
             
              <Link className="collapse-item" to="/client/strategy/master">
                Master Strategies
              </Link>
              <Link className="collapse-item" to="/client/strategy/deploy">
                Deploy Strategies
              </Link>            
             
            </div>
          </div>
        </li>

        <li className="nav-item">
          <Link to={"/client/subscriptions"} className="nav-link" style={{cursor:'pointer'}}>
            <FaCreditCard/>
            <span className='ml-2'>Subscription</span>
          </Link>
        </li>
        {/* <!-- Divider --> */}
        <hr className="sidebar-divider" />

        <li className="nav-item">
          <b className="nav-link" style={{cursor:'pointer'}} onClick={logout}>
            <FaSignOutAlt/>
            <span className='ml-2'>LogOut</span>
          </b>
        </li>

        {/* <!-- Divider --> */}
        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul>
    </>
  );
}
