import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { CurrencyRupee, EditSharp } from "@mui/icons-material";
import { FaChevronDown, FaChevronUp, FaTrash } from "react-icons/fa";
import { useRef } from "react";
import toast from "react-hot-toast";

const DeployedItem = ({ obj, token, removeFromList, updateDeployItem }) => 
{
      const [showRun,setShowRun] = useState(false);
      const brokers = useSelector((store) => store.clientinfo.value.brokers);
    
      const [strategy, setStrategy] = useState(undefined);
      const [runningStrategy,setRunningStrategy] = useState([])
      const [show, setShow] = useState(false);

      const [totalPNL,setTotalPNL] = useState({})
    
      const [editPlan,setEditPlan] = useState({
          qtyMultiply: 1,
          squareoff: strategy?.squareoff_time,
          broker: null,
      })
    
      useEffect(() => {
        loadStrategy();
        loadRunningStrategy();
      }, []);
    
      const loadRunningStrategy = async()=>
      {
        const url = URLS.LIST_RUNNING_STRATEGY + obj._id;
        const res = await apiCall.getCallWithToken(url, token);
        //console.log("RUNNING_STRATEGY : ", res);
        if(res.status)
        {
            setRunningStrategy(res.data);
        }
      }

      const deleteRunningStrategy = (id)=>{       
          setRunningStrategy(runningStrategy.filter(rs=>rs._id!=id))
      }
      const updateRunningStrategy = (newItem)=>{       
        setRunningStrategy(runningStrategy.map(rs=>rs._id===newItem._id?newItem:rs))
      }
    
      const handlePopClose = () => {
        setShow(false);
      };
      const loadStrategy = async () => {
        const url = URLS.GET_ACTIVE_STRATEGY + obj.strategy;
        const res = await apiCall.getCallWithToken(url, token);
        console.log(res);
        if (res.status) {
          setStrategy(res.data);
          setEditPlan({...editPlan,squareoff : res.data.squareoff_time})
        }
      };
    
      const unDepoly = () => {
        if(runningStrategy.length==0)
        {
          const url = URLS.UNDEPLOY_STRATEGY + obj._id;
          apiCall.patchCallWithToken(url, token).then((res) => {
            if (res.status) {
              removeFromList(obj._id);
            }
          });
        }else
          toast.error("Strategies are running.")
      };
    
      const edit = () => {
        setShow(true);
      };
    
      const startStrategy = async () => {
        //console.log(obj)
        if (editPlan.broker == null || editPlan.broker == undefined) {
          toast.error("Choose Broker to Execute !");
        } else {
          const brokerDeployStatus = brokers.some(
            (b) => b.name == editPlan.broker && b.isdeployon
          );
          if (brokerDeployStatus) 
          {
            const did = obj._id;
            const URL = URLS.START_STRATEGY + did;
          
            const result = await apiCall.postCallWithToken(URL, token , editPlan);
            if (result.status) 
            {
              const newItem = { ...obj, isrunning: true };
              //updateDeployItem(did, newItem);
              toast.success(result.msg);
    
              const {diff,currentStartStrategy} = result.data;
              setRunningStrategy([...runningStrategy,currentStartStrategy])
              //setOrderDiff(diff);
              //startOrderCounter();
            } else {
              toast.error(result.msg);
            }
          } else toast.error(`${editPlan.broker} Broker Deploy Off !`);
        }
      };
    
     
    
      const changeStrategyPNL = (id,pnl)=>
      {
          const pnlrec = {...totalPNL};
          pnlrec[id] = pnl;
          setTotalPNL(pnlrec);
      }
      const getTotalPNL = ()=>
      {
          const pnl = Object.values(totalPNL).reduce((pv,cv)=>pv+cv,0);
          return pnl.toFixed(2);
      }
    
    
      return (
        <>
          {show ? (
            <EditDeployStrategyPop
              name={strategy?.name}
              obj={obj}
              brokers={brokers}
              setEditPlan={setEditPlan}
              editPlan={editPlan}
              show={show}
              handlePopClose={handlePopClose}
            />
          ) : (
            <></>
          )}
          {strategy ? <>
            <div className="row mt-1 border shadow rounded-2 p-3">
              <div className="col-xl-4 col-lg-5">
                <h4 style={{ fontSize: "1.2em" }}>
                  {strategy?.name} ({JSON.parse(strategy.instrument).name})
                </h4>
                &nbsp;
                <b className="text-danger border shadow">{editPlan.broker}</b> <br />
                {strategy.legs.map((l) => (
                  <>
                    <b
                      style={{ fontSize: "0.8em" }}
                      className={l.type == "BUY" ? "text-success" : "text-danger"}
                    >
                      {`${l.ttype}${l.qty * editPlan.qtyMultiply}${l.tm.toUpperCase()}`}
                    </b>{" "}
                    &nbsp;&nbsp;
                  </>
                ))}
              </div>
              <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
                {/* PNL :{" "}
                <CurrencyRupee style={{ fontSize: "1em" }} className="text-dark" />{" "}
                {getTotalPNL()} */}
              </div>
              <div className="col-xl-3 col-lg-2 d-flex justify-content-center align-items-center">
                 <button className="btn-sm btn-primary" onClick={startStrategy}>
                    Start
                  </button>
                  &nbsp;
                  <button className="btn-sm btn-danger" onClick={unDepoly}>
                    Stop
                  </button>
              </div>
    
              <div className="col-xl-2 col-lg-1 d-flex justify-content-evenly align-items-center">
               
                    {/* <button onClick={unDepoly} className="btn-sm btn-danger">
                      <FaTrash />
                    </button> */}
                    <EditSharp
                      onClick={edit}
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      title="Edit"/>
                   
                  {showRun?<FaChevronUp onClick={()=>setShowRun(false)}/>:<FaChevronDown onClick={()=>setShowRun(true)}/>}
              </div>
            </div>
            
            <div style={{display: showRun?'block':'none'}}>
            {runningStrategy.map((runStr,index)=><OrderItem key={`run${index}`} token={token} index={index} 
            strategy={strategy} 
            runStr={runStr} 
            changeStrategyPNL={changeStrategyPNL}
            updateRunningStrategy={updateRunningStrategy} 
            deleteRunningStrategy={deleteRunningStrategy}/>)}
            </div>
          </> : (
            <></>
          )}
        </>
      );
    
     
    };
    
const OrderItem = ({index,token,runStr,strategy,changeStrategyPNL,deleteRunningStrategy,updateRunningStrategy})=>
{
      const clientinfo = useSelector(store=>store.clientinfo.value.brokers)
      const [orderDiff, setOrderDiff] = useState(undefined);
      const orderIntervalRef = useRef(undefined);

      const [orders,setOrders] = useState([]);
      const fetchPriceRef = useRef(undefined);

      useEffect(()=>
      {
          if(runStr.isordered)
          {
            fetchPriceRef.current = setInterval(()=>{
              loadCurrentPrices();
            },3000) 
          }

          return ()=>{
            clearInterval(fetchPriceRef.current)
          }
      },[runStr])

      useEffect(()=>{
        if(runStr.isordered==false)
        {
        if(orderDiff==undefined)
        {
            const diff = getOrderDiff(strategy);
            if(diff>0){
              setOrderDiff(diff);
              startOrderCounter();
            }
        }else
        {
          if(orderDiff==0)
          {
              if (orderIntervalRef != undefined)
                clearInterval(orderIntervalRef.current);
              updateRunningStrategy({...runStr,isordered:true,action:"Order Placed"})
            
              // Start Order Info TimeOut
              setTimeout(getOrderInfo,5000);
            }
        }  
      }      
      },[orderDiff]);

      const loadCurrentPrices = async()=>
      {
         const priceResult = await apiCall.postCallWithToken(URLS.FETCH_CURRENT_PRICES,token,{runningStrategyID : runStr._id});
         if(priceResult.status){
            setOrders(priceResult.data);
            //console.log(">> " , runStr._id , priceResult.data.length)
         }
      }

      const startOrderCounter = () => {
        orderIntervalRef.current = setInterval(() => 
        {
          console.log("counter run")
          setOrderDiff((pdiff) => pdiff - 1000);
        }, 1000);
      };

      function convertMilliseconds(ms) 
      {
        if(ms<=0)
          return "Order Placed .."; 
        // Calculate total seconds
        let totalSeconds = Math.floor(ms / 1000);
        // Calculate hours
        let hours = Math.floor(totalSeconds / 3600);
        // Calculate minutes
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        // Calculate seconds
        let seconds = totalSeconds % 60;
    
        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
    
        return `${hours} : ${minutes} : ${seconds}`;
      }

      function getOrderDiff(strategy) 
      {
        const td = getIndianDate();
        const start_time = strategy.start_time;
        let timeString = `${td.getFullYear()}-${td.getMonth()}-${td.getDate()} ${start_time}`;
        let strategy_timestamp = Date.parse(timeString);
    
        const cTimeStr = parseTimeDate();
        let ctimeString = `${td.getFullYear()}-${td.getMonth()}-${td.getDate()} ${cTimeStr}`;
        let current_timestamp = Date.parse(ctimeString);
        const diff = strategy_timestamp - current_timestamp;
        return diff;
      }
    
      function getIndianDate() {
        const date = new Date();
        //console.log(date)
    
        const offsetInMinutes = date.getTimezoneOffset();
    
        let utcDate = undefined;
    
        if (offsetInMinutes < 0)
          utcDate = new Date(
            date.getTime() + Math.abs(offsetInMinutes) * 60 * 1000
          );
        else if (offsetInMinutes > 0)
          utcDate = new Date(
            date.getTime() - Math.abs(offsetInMinutes) * 60 * 1000
          );
        else utcDate = date;
    
        return utcDate;
      }
    
      function parseTimeDate() {
        const tm = new Date().toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZoneName: "short",
        });
        const isPM = tm.includes("PM");
    
        const i1 = tm.indexOf(":");
        const i2 = tm.indexOf(" ", i1);
    
        let hour = tm.substring(i1 - 2, i1);
        if (isPM && hour != 12) {
          hour = hour * 1 + 12;
        }
    
        let time = "" + hour + tm.substring(i1, i2);
        return time.trim();
      }

      const getOrderInfo = ()=>
      {
          
      }

      const showStopConfirmBox = () => {
        toast(
          (t) => (
            <div>
              <p>Are you sure to stop / delete ?</p>
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    toast.dismiss(t.id); // Close the toast
                    stopStrategy(); // Handle confirmation logic
                  }}
                  style={{
                    background: 'green',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Confirm
                </button>
                <button
                  onClick={() => toast.dismiss(t.id)} // Close the toast
                  style={{
                    background: 'red',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ),
          { duration: Infinity } // Keep the toast open until the user interacts
        );
      };

        const stopStrategy = async () => 
        {
          const did = runStr._id;
          const URL = URLS.STOP_STRATEGY + did;
          const result = await apiCall.postCallWithToken(URL, token);
          if (result.status) {
            toast.success(result.msg);
            clearInterval(orderIntervalRef.current);
            setOrderDiff(undefined);
            deleteRunningStrategy(did);
          } else {
            toast.error(result.msg);
          }
        };

      const getTotalPNL = ()=>
      {
        let pnl = 0;
        for(let leg of runStr.legs){
          const legpnl = getPNL(leg,runStr.qtyMultiply,orders);
          //console.log(legpnl , typeof legpnl)
          pnl += legpnl;
        }
        //changeStrategyPNL(runStr._id,pnl);
        return pnl.toFixed(2);
      }
      const getPNL = (sl,qtyMultiply,orders)=>
      {
        const order = orders.find(ord=>ord.legid===sl.legid);
        if(order==null || order==undefined || order.status==false)
          return 0.0;
        else
        {
          let pnl = 0.0;
          const tradeQty = sl.qty * qtyMultiply;
          if(sl.type==="BUY")
          {
            pnl = (order.price - order.tradePremium)*tradeQty;
          }
          else
          {
            pnl = (order.tradePremium - order.price)*tradeQty;
          }
          return isNaN(pnl)?0:pnl;
        }
      }

      const checkOrderStatus = (sl,qtyMultiply,orders)=>
      {
        const order = orders.find(ord=>ord.legid===sl.legid);
        if(order==null || order==undefined)
          return {msg : "PNL : 0.0" , pnl:0};
        else
        {
          if(order.status==false)
            return {msg : order.order_status , pnl : 0};
          else
          {
              const pnl = getPNL(sl,qtyMultiply,orders).toFixed(2);
              return { msg : "PNL: "+pnl , pnl}
          }
        }
      };

      const showSquareOffConfirmBox = () => {
        toast(
          (t) => (
            <div>
              <p>Are you sure to SquareOff ?</p>
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    toast.dismiss(t.id); // Close the toast
                    squareOff(); // Handle confirmation logic
                  }}
                  style={{
                    background: 'green',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Confirm
                </button>
                <button
                  onClick={() => toast.dismiss(t.id)} // Close the toast
                  style={{
                    background: 'red',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          ),
          { duration: Infinity } // Keep the toast open until the user interacts
        );
      };

      const squareOff = async ()=>
      {
        const {_id,broker} = runStr;
        const brokerObj = clientinfo.find(cl=>cl.name===broker);
        //console.log(_id,broker,brokerObj)
        if(brokerObj.isterminalon)
        {
          await apiCall.postCallWithToken(URLS.SQUARE_OFF,token,{runningStrategyID:_id,clientBrokerID:brokerObj.id});
        }else{
          toast.error(`${broker} Terminal Off.`)
        }
      }

      return <div  className="row border shadow rounded-2 p-3 alert-success">
      <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
        {runStr.isordered?<div>
          <b className="text-danger">{runStr.action}</b>
          <br/>
          {orders.length>0?orders.some(ord=>ord.status)?<button className="btn-sm btn-info" onClick={showSquareOffConfirmBox}>SquareOff</button>:<button className="btn-sm btn-danger" onClick={showStopConfirmBox}>Delete</button>:""} 
        </div>:<div>
          <button className="btn-sm btn-danger" onClick={showStopConfirmBox}>Stop</button>
          <br/><br/>
          <b className="text-danger">{convertMilliseconds(orderDiff)}</b>
        </div>}
       
      </div>
      <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
        <CurrencyRupee style={{ fontSize: "1.5em" }} className="text-dark" />
        <b>{getTotalPNL()}</b>
      </div> 
      {runStr.legs.map((sl,index)=>
      {
        const pnl = checkOrderStatus(sl,runStr.qtyMultiply,orders);
        return <div className="col-xl-2 col-lg-2 d-flex justify-content-center align-items-center">
            <div className={pnl.pnl<=0?'alert-danger p-2 rounded':'alert-success p-2 rounded'}>
            {sl.ttype}:{sl.tm.toUpperCase()}:{sl.type}
            <br/>
            {pnl.msg}
            </div>
        </div>
      })}
  </div>
    }

function EditDeployStrategyPop({
      name,
      obj,
      brokers,
      show,
      handlePopClose,
      setEditPlan,
      editPlan,
    }) {
      let popObj = {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        height: "fit-content",
        width: "max-content",
        maxWidth: "70%",
        minWidth: "400px",
        backgroundColor: "white",
        borderRadius: "10px",
      };
      const token = useSelector((store) => store.user.value.token);
      const [isAccept, setIsAccept] = useState(false);
    
      const qtyMultiplyRef = useRef();
      const brokerRef = useRef();
      const squareOffRef = useRef();
    
      const updateItem = () => 
      {
        const qtyMultiply = qtyMultiplyRef.current.value * 1;
        const broker = brokerRef.current.value;
        const squareoff = squareOffRef.current.value;
    
        const newItem = {
          qtyMultiply,
          broker: broker.length == 0 ? null : broker,
          squareoff,
        };
        
        setEditPlan(newItem);
        handlePopClose()
      };
    
      return (
        <div
          className={`modal fade ${show ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div style={popObj} className="p-4">
            <div className="modal-header">
              <h5 className="modal-title">{name}</h5>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true" onClick={handlePopClose}>
                  &times;
                </span>
              </button>
            </div>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="quantity">Quantity Multiplied</label>
                  <input
                    type="number"
                    ref={qtyMultiplyRef}
                    className="form-control"
                    id="quantity"
                    defaultValue={editPlan.qtyMultiply}
                    min="1"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="brokerName">Broker Name</label>
                  <select
                    className="form-control"
                    ref={brokerRef}
                    id="brokerName"
                    defaultValue={editPlan.broker}
                  >
                    <option value="">Select Broker</option>
                    {brokers.map((b) => (
                      <option disabled={!b.isdeployon}>{b.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="maxProfit">Max Profit (Optional)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="maxProfit"
                    defaultValue="0"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="squareOffTime">Square Off Time</label>
                  <input
                    type="time"
                    className="form-control"
                    ref={squareOffRef}
                    id="squareOffTime"
                    defaultValue={editPlan.squareoff}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="maxLoss">Max Loss (Optional)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="maxLoss"
                    defaultValue="0"
                  />
                </div>
                {/* <div className="form-group col-md-6">
                  <label>Deployment Type</label>
                  <div className="d-flex">
                    <button type="button" className="btn btn-outline-primary mr-2">
                      Live
                    </button>
                    <button type="button" className="btn btn-primary">
                      Forward Test
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terms"
                    checked={isAccept}
                    onChange={(e) => setIsAccept(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="terms">
                    I accept all the <a href="/"> &nbsp;terms & conditions</a>
                  </label>
                </div>
              </div>
            </form>
            <hr />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handlePopClose}
            >
              Close
            </button>
            &nbsp;&nbsp;
            {isAccept ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={updateItem}
              >
                Update
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }





    export default DeployedItem;