import { createSlice } from "@reduxjs/toolkit";

function getLocalData()
{
    const uinfo = localStorage.getItem('clientinfo');
    if(uinfo==undefined){
        return {
            brokers : []
        }
    }else
        return JSON.parse(uinfo)
}

const slice = createSlice({
    name : 'clientdataslice',
    initialState:{
        value : getLocalData()
    },
    reducers:
    {
       addBrokers : (state,action)=>
       {
            const brokers = action.payload.map(bk=>{
                let {id,name,clientid,isdefault,isdelete,isdeployon,isterminalon} = bk;
                let details = {appname : JSON.parse(bk.details).appname}
                return {id,name,clientid,isdefault,isdelete,isdeployon,isterminalon,details}
            })
            state.value = {...state.value , brokers}
       },
       addNewBroker : (state,action)=>
       {
            let {id,name,clientid,isdefault,isdelete,isdeployon,isterminalon} = action.payload;
            let details = {appname : JSON.parse(action.payload.details).appname}
            state.value = {...state.value , brokers : [...state.value.brokers,{id,name,clientid,isdefault,isdelete,isdeployon,isterminalon,details}]}
       },
       editBrokerTerminal : (state,action)=>
        {
            const bid = action.payload;

            const brokers = state.value.brokers.map((b) => b.id == bid ? { ...b, isterminalon: !b.isterminalon } : b);
            state.value = {...state.value , brokers}
        },
        editBrokerDeploy : (state,action)=>
            {
                const bid = action.payload;
    
                const brokers = state.value.brokers.map((b) => b.id == bid ? { ...b, isdeployon: !b.isdeployon } : b);
                state.value = {...state.value , brokers}
            }
    }
})

export default slice.reducer;
export const {addBrokers,addNewBroker,editBrokerDeploy,editBrokerTerminal} = slice.actions;