import React, { useEffect, useState } from "react";
import ClientPayPop from "./ClientPayPop"; 
import PricingPlans from "../../web/plans/PricingPlans";
import plansHOF from "../../web/plans/PlansHOF";
import apiCall , {URLS} from "../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const ClientSubscription = () => 
{
  const [show,setShow]=useState(false);
  const [plan,setPlan]=useState(undefined);
  const [prePayments , setPrePayments] = useState([]);
  const loginUser = useSelector((store) => store.user.value);

  useEffect(()=>{
    loadPayments();
  },[])

  const loadPayments = async()=>
  {
      const result = await apiCall.getCallWithToken(URLS.LIST_SUBSCRIPTION,loginUser.token);
      if(result.status){
        setPrePayments(result.data);
      }
  }

  
  const handlePopClose=()=>{
    setShow(false);
  }

  const plansEventHandlers = {
    onSelectPlan: (plan) => {
       setPlan(plan);
       setShow(true);
    },
  };
  const PricingNewComp = plansHOF(PricingPlans,plansEventHandlers);

  return <>
      &nbsp; <Link to="/client/dashboard"><b className='btn btn-outline-primary mt-3'>Home</b></Link>
      <PricingNewComp/>
      {show && <ClientPayPop plan={plan} show={show} handlePopClose={handlePopClose}/>}
      <hr/>
      <h3 id="HeadText">Previous Payments</h3>
      <table className="table mt-3">
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Plan</th>
            <th>Payment Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
            {prePayments.map((ob,index)=><tr key={`pp${index}`}>
              <td>{index+1}</td>
              <td>
                <b>{ob.plan_name}</b> <br/>
                {ob.duration_month} Days
              </td>
              <td>{ob.payment_date.split("T")[0]}</td>
              <td>{ob.amount}</td>
            </tr>)}
        </tbody>
      </table>
    </>
};

export default ClientSubscription;
