import { useEffect, useState } from "react";
import apiCall, { URLS } from "../../../webservice/ApiCallService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrencyRupee, EditSharp, Padding } from "@mui/icons-material";
import { FaChevronDown, FaTrash } from "react-icons/fa";
import { useRef } from "react";
import toast from "react-hot-toast";
import DeployedItem from "./DeployedItem";

export default function ClientDeployStrategyList() 
{
  const navigate = useNavigate();
  const [deployList, setDeployList] = useState([]);
  const token = useSelector((store) => store.user.value.token);

  useEffect(() => {
    loadDeployStrategy();
  }, []);

  const removeFromList = (id) => {
    setDeployList(deployList.filter((ob) => ob._id != id));
  };

  const updateDeployItem = (id, newItem) => {
    setDeployList(deployList.map((ob) => (ob._id == id ? newItem : ob)));
  };

  const loadDeployStrategy = async () => {
    const depStrList = await apiCall.getCallWithToken(
      URLS.LIST_DEPLOY_STRATEGY,
      token
    );
    setDeployList(depStrList.data);
  };

  return (
    <>
      <div className="container-fluid pt-3">
        <h3 id="HeadText">Deployed Strategies</h3>

        {deployList.map((ob, index) => (
          <div
            key={`dp${index}`}
          >
            <DeployedItem
              obj={ob}
              token={token}
              key={`dpi${index}`}
              removeFromList={removeFromList}
              updateDeployItem={updateDeployItem}
            />
          </div>
        ))}
      </div>
    </>
  );
}


